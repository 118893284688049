<template>
    <div>
        <b-card>
            <b-card-body>
                <b-row>
                    <b-col md="2">
                        <label for="search">Search :</label>
                    </b-col>
                    <b-col md="9">
                        <b-input id="search" v-model="email" placeholder="email"></b-input>
                    </b-col>
                    <b-col md="1">
                        <b-button @click="search" variant="primary">
                            <b-icon icon="search"></b-icon>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <p class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem"
          v-if="loading"
          label="Large Spinner"
        ></b-spinner>
      </p>
        <b-table striped hover :items="items"></b-table>
         <b-pagination
        @input="pageChange(currentPage)"
      v-model="currentPage"
      v-if="!filterdData"
      align="center"
      :total-rows="rows"
      :per-page="perPage"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination>
    </div>
</template>
<script>
const {getFields,getFieldsByOneProperty,getPerPage,getFieldsPage} = require("../../assets/js/service")
export default {watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  },
    data() {
        return {
            email:null,
            items:[],
             currentPage:1,
             loading:false,
            rows:1,
            perPage:1,
            filterdData:false
        }
    },
    methods: {
        pageChange(currentPage){
      let token = localStorage.getItem('token'); this.$store.commit("set", ["loading", true]);
      getFieldsPage('comments',currentPage,token).then(resp=>{
          this.filterdData =true;this.$store.commit("set", ["loading", false]);
 this.items = resp.data.map(function(item) { 
    delete item.id; 
    item.date = Date(item.date)
    return item; 
});;
      })
    },
        search(){
            
            if(this.email){
                let token = localStorage.getItem('token');
                getFieldsByOneProperty('comments','email',this.email,token).then(resp=>{
                    this.items=resp.data.map(function(item) { 
    delete item.id; 
    item.date = Date(item.date)
    return item; 
});;
                })
            }
        }
    },
    mounted() {
         let token = localStorage.getItem('token');
    getFields('comments/count',token).then(resp=>{
      this.rows = Math.ceil(resp.data.count / getPerPage());
      getFieldsPage('comments',1,token).then(resp=>{
 this.items = resp.data.map(function(item) { 
    delete item.id; 
    item.date = Date(item.date)
    return item; 
});;
      })
    })
       
    },

}
</script>
<style>

</style>